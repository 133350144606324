import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App/App';
import { unregister } from './registerServiceWorker';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
unregister();
